import api from "../api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function LocationEdit() {
    const params = useParams();
    const locationId = params.locationId;
    // if (!params) params = 1;
    const urlLocations = "/locations/" + locationId;

    const [location, setLocation] = useState();
    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await api.get(urlLocations);
                if (response && response.data) setLocation(response.data);
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(`Error: ${error.message}`);
                }
            }
        };

        fetchLocations();
    }, []);

    const seriesId = location?.series?.id;

    const [dataSeries, setSeries] = useState();
    useEffect(() => {
        const fetchSeries = async () => {
            try {
                const response = await api.get("/series");
                if (response && response.data) setSeries(response.data);
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(`Error: ${error.message}`);
                }
            }
        };
        fetchSeries();
    }, []);

    let m = "";
    if (!location?.media[0]?.mediaName) {
        m = "undefined.jpg";
    } else {
        m = location?.media[0]?.mediaName;
    }
    const mediaLink = "https://img.salamini.cloud/umsh/" + m;

    // console.log(location);

    if (!location) return null;

    return (
        <>
            <h2>{location.locationName}</h2>
            {/* <div calss="row">
                <div class="col"></div>
                <div class="col">
                    <button>Prima</button>
                    <button>Dopo</button>
                </div>
            </div> */}
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="media">
                        <img src={mediaLink} class="img-fluid rounded" alt={location.locationName} />
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <div class="form-group">
                                <label for="test">Latitudine</label>
                                <input type="text" class="form-control" value={location.lat} id="test" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label for="test">Longitudine </label>
                                <input type="text" class="form-control" value={location.lng} id="test" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label for="test">Serie associata</label>
                        <select class="form-control" id="test">
                            <option value="0"></option>
                            {dataSeries?.map((serie) =>
                                serie.id == seriesId ? (
                                    <option selected value={serie.id}>
                                        {serie.seriesName}
                                    </option>
                                ) : (
                                    <option value={serie.id}>{serie.seriesName}</option>
                                )
                            )}
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="test">Nome location</label>
                        <input type="text" class="form-control" value={location.locationName} id="test" />
                    </div>
                    <div class="form-group">
                        <label for="test">Descrizione</label>
                        <textarea class="form-control" id="test" rows="3">
                            {location.locationDescription}
                        </textarea>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col">Column</div>
                <div class="col">Column</div>
            </div>
        </>
    );
}
