import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <nav class="navbar navbar-dark bg-dark navbar-expand-lg">
            <div class="container-fluid">
                <Link to="/" class="navbar-brand">
                    Navbar
                </Link>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <Link to="/" class="nav-link" aria-current="page">
                                Home
                            </Link>
                            {/* <Link to="/" class="nav-link active" aria-current="page">
                Home
              </Link> */}
                        </li>
                        <li class="nav-item">
                            {/* <Link to="/edit" class="nav-link">
                                Edit
                            </Link> */}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
