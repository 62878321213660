import { Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar.jsx";

import Home from "./pages/Home.js";

import SeriesDetails from "./pages/SeriesDetails.js";
import LocationEdit from "./pages/LocationEdit.js";

function App() {
    return (
        <>
        <Navbar />
        <div class="container-md pt-5">
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/seriesDetails/:seriesId" element={<SeriesDetails />}></Route>
                <Route path="/locationEdit/:locationId" element={<LocationEdit />}></Route>
            </Routes>
        </div>
        </>
    );
}

export default App;
