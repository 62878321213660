import { useEffect, useState } from "react";
import axios from "axios";

import SeriesCard from "../components/SeriesCard.jsx";

// import { getAllSeriesData } from "../api/index.js";

import api from "../api/index.js";

export default function Home() {
    const [series, setSeries] = useState();
    useEffect(() => {
        const fetchSeries = async () => {
            try {
                const response = await api.get("/series");
                if (response && response.data) setSeries(response.data);
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(`Error: ${error.message}`);
                }
            }
        };

        fetchSeries();
    }, []);

    if (!series) return null;

    return (
        <>
            <div class="row">
                <div class="col mb-5">
                    <h2>Mappona</h2>
                    <div id="map">
                        <img src="https://via.placeholder.com/1295x720" class="rounded" alt="Mappa" />
                    </div>
                </div>
            </div>

            <div class="row g-3">
                {series?.map((serie) => (
                    <SeriesCard data={serie} />
                ))}
            </div>
        </>
    );
}
