import React from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const LocationsList = ({ data }) => {
    
    const m = data.media?.[0]?.mediaName;
    const mediaLink = "https://img.salamini.cloud/umsh/" + m;
    
    const link = "/locationEdit/" + data.id;

    console.log(m);

    return (
        <ul class="list-group">
            <li class="list-group-item">
                <Link to={link}>
                    <div class="row">
                        <div class="col">
                            <h6 class="position-relative top-50">{data.locationName}</h6>
                        </div>
                        <div class="col">
                            <p class="position-relative top-50">{data.locationDescription}</p>
                        </div>
                        <div class="col">
                            <p class="position-relative top-50">{data.lat}</p>
                            <p class="position-relative top-50">{data.lng}</p>
                        </div>
                        <div class="col">
                            <img src={mediaLink} class="rimg-fluid rounded" alt={data.locationName} />
                        </div>
                    </div>
                </Link>
            </li>
        </ul>
    );
};

export default LocationsList;
