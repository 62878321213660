import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LocationsList from "../components/LocationsList";
import api from "../api";

export default function SeriesDetails() {
    const params = useParams();
    const seriesId = params.seriesId;
    const urlSeries = "/series/" + seriesId;

    const [dataSeries, setSeries] = useState();
    useEffect(() => {
        const fetchSeries = async () => {
            try {
                const response = await api.get(urlSeries);
                if (response && response.data) setSeries(response.data);
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(`Error: ${error.message}`);
                }
            }
        };
        fetchSeries();
    }, []);


    const urlLocations = "/locations/series/" + seriesId;
    const [dataLocations, setLocations] = useState(null);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await api.get(urlLocations);
                if (response && response.data) setLocations(response.data);
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(`Error: ${error.message}`);
                }
            }
        };
        fetchLocations();
    }, []);



    if (!dataSeries) return null;


    return (
        <>
            <div class="row mh-50">
                <h1>{dataSeries.seriesName}</h1>
                <div class="col-12 col-md-6">
                    <img src={"https://img.salamini.cloud/banner/" + dataSeries.seriesImg} class="card-img-top" alt={dataSeries.seriesName} />
                </div>
                <div class="col-12 col-md-6">
                    <p>{dataSeries.seriesDescription}</p>
                </div>
            </div>
            <div class="row mt-5">
                {dataLocations?.map((location) => (
                    <LocationsList data={location} />
                ))}
            </div>
        </>
    );
}
