import React from "react";
import { Link } from "react-router-dom";

const SeriesCard = ({ data }) => {
    const link = "/seriesDetails/" + data.id;
    return (
    <div class="col-12 col-md-6 col-lg-3">
        <div class="card">
            <Link to={link}>
                <img src={"https://img.salamini.cloud/banner/"+data.seriesImg} class="card-img-top" alt={data.seriesName}/>
                {/* <img src="https://via.placeholder.com/680x1000" class="card-img-top" alt={serie.seriesImg} /> */}
            </Link>
            <div class="card-body">
                <h5 class="card-title">{data.seriesName}</h5>
                <p class="card-text">{data.seriesDescription}</p>
            </div>
        </div>
    </div>
    );
};
export default SeriesCard;
